<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="사보 수정">
            - 첨부파일은 각각 최대 2MB까지 파일을 올리실 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 사보명</th>
                                <td>
                                     <input type="text" v-model="bbs.magazine_name" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 제목</th>
                                <td>
                                    <input type="text" v-model="bbs.title" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                              <th>제목(영문)</th>
                              <td>
                                <input type="text" v-model="bbs.title_en" class="w-100per">
                              </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span> 미리보기(한글)</th>
                              <td>
                                <input type="text" v-model="bbs.preview_ko" class="w-100per">
                              </td>
                            </tr>
                            <tr>
                              <th> 미리보기(영문)<br>(ITM/CTM메인 노출용 영문)</th>
                              <td>
                                <input type="text" v-model="bbs.preview_en" class="w-100per">
                              </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>썸네일</th>
                                <td>
                                    <a v-if="bbs.file1_name" :href="bbs.file1_url" class="btn-view" target="_blank">{{ bbs.file1_name }}</a>
                                    <label v-if="bbs.file1_name" class="ml-10"><input type="checkbox" v-model="bbs.dchk1"> 삭제</label>
                                    <CarrotFileUploader ref="file1"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>본문 jpg</th>
                                <td>
                                    <a v-if="bbs.file2_name" :href="bbs.file2_url" class="btn-view" target="_blank">{{ bbs.file2_name }}</a>
                                    <label v-if="bbs.file2_name" class="ml-10"><input type="checkbox" v-model="bbs.dchk2"> 삭제</label>
                                    <CarrotFileUploader ref="file2"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                              <th>본문 jpg(영문)<br>(ITM/CTM메인 노출용 영문)</th>
                              <td>
                                <a v-if="bbs.file4_name" :href="bbs.file4_url" class="btn-view" target="_blank">{{ bbs.file4_name }}</a>
                                <label v-if="bbs.file4_name" class="ml-10"><input type="checkbox" v-model="bbs.dchk4"> 삭제</label>
                                <CarrotFileUploader ref="file4"></CarrotFileUploader>
                              </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>크게 보기(PDF)</th>
                                <td>
                                    <a v-if="bbs.file3_name" :href="bbs.file3_url" class="btn-view" target="_blank">{{ bbs.file3_name }}</a>
                                    <label v-if="bbs.file3_name" class="ml-10"><input type="checkbox" v-model="bbs.dchk3"> 삭제</label>
                                    <CarrotFileUploader maxsize="5" ref="file3"></CarrotFileUploader>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="bbs.showList">목록</button>
                    <button class="btn-default float-right mt-30" @click="bbs.onSave">수정하기</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
//import CarrotEditor from '@/components/common/CarrotEditor.vue'
//import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'

export default {
    layout:"ResourceManagement",
    components: {
      //CarrotDatePicker,        
      // CarrotEditor
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast = useToast();

        const file1 = ref(null);
        const file2 = ref(null);
        const file3 = ref(null);
        const file4 = ref(null);
        const bbs = reactive({
            title : "",
            title_en:"",
            private_en:"",
            private_ko:"",
            content: "",
            magazine_name : "",

            file1_name : "", file1_url : "",
            file2_name : "", file2_url : "",
            file3_name : "", file3_url : "",

          file4_name : "", file4_url : "",

            dchk1 : false, dchk2 : false, dchk3 : false, dchk4 : false,

          showList: () => {
                router.push({ name:"ResourceManagement-MagazineManagementList" });
            },

            
            getInfo: () => {
                let params = {
                    idx : bbs.idx
                };
                axios.get("/rest/resourceManagement/magazineView", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.title   = res.data.title;
                        bbs.title_en   = res.data.title_en;
                        bbs.preview_ko   = res.data.preview_ko;
                        bbs.preview_en   = res.data.preview_en;
                        bbs.magazine_name = res.data.magazine_name;

                        bbs.file1_name = res.data.file1_name;
                        bbs.file1_url  = res.data.file1_url;
                        bbs.file2_name = res.data.file2_name;
                        bbs.file2_url  = res.data.file2_url;
                        bbs.file3_name = res.data.file3_name;
                        bbs.file3_url  = res.data.file3_url;
                        bbs.file4_name = res.data.file4_name;
                        bbs.file4_url  = res.data.file4_url;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },


            onSave: () => {
                if( !bbs.magazine_name ){
                    toast.error("사보명을 입력하세요.");
                    return;
                }
                if( !bbs.title ){
                    toast.error("제목을 입력하세요.");
                    return;
                }
                var formData = new FormData();
                formData.append("idx", bbs.idx);
                formData.append("topfix", bbs.topfix==true?"Y":"N");
                formData.append("title", bbs.title);
                formData.append("title_en", bbs.title_en);
                formData.append("preview_en", bbs.preview_en);
                formData.append("preview_ko", bbs.preview_ko);
                formData.append("magazine_name", bbs.magazine_name);

                if(file1.value.file.filedata!==null) {
                    formData.append("nfile1", file1.value.file.filedata);
                }
                if(file2.value.file.filedata!==null) {
                    formData.append("nfile2", file2.value.file.filedata);
                }
                if(file3.value.file.filedata!==null) {
                    formData.append("nfile3", file3.value.file.filedata);
                }
                if(file4.value.file.filedata!==null) {
                  formData.append("nfile4", file4.value.file.filedata);
                }
                formData.append("dchk1", bbs.dchk1==true?"Y":"N");
                formData.append("dchk2", bbs.dchk2==true?"Y":"N");
                formData.append("dchk3", bbs.dchk3==true?"Y":"N");
                formData.append("dchk4", bbs.dchk4==true?"Y":"N");
                
                axios.post('/rest/resourceManagement/magazineMod', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"ResourceManagement-MagazineManagementView-idx",
                            params : { idx:bbs.idx }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            }
        });
        

        onMounted(() => {
            // Mounted
            bbs.idx = route.params.idx;
            if ( !bbs.idx ) {
                router.back(-1);
            }

            bbs.getInfo();

        });

        return {bbs, file1, file2, file3, file4};
    }
}
</script>

<style lang="scss" scoped>
</style>